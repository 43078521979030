<template>
  <div class="w-full flex justify-center items-start mb-16">
    <div class="w-full mb-2 simpleCard">
      <div v-for="(data, index) in mockup" v-bind:key="index">
        <h2
          v-if="data && data.type === 'title' && data.title"
          class="section text-blue text-left customTitle"
        >
          <b>{{ data.title }}</b>
        </h2>

        <div
          v-if="data && data.type === 'accordion'"
          class="section accordionBox"
        >
          <Accordion :header="data.title">
            <div :class="'boxValues'">
              <div
                v-for="(item, index2) in data.content"
                v-bind:key="index2"
                :class="
                  processStyles(
                    'boxText section',
                    item,
                    'width',
                    'section accordionBox'
                  )
                "
              >
                <p v-if="item && item.type === 'text' && item.title">
                  {{ item.title }}
                </p>
                <small v-if="item && item.type === 'text' && item.subtitle">{{
                  item.subtitle
                }}</small>
                <div
                  v-if="item && item.type === 'text' && item.value"
                  class="boxValue"
                >
                  <span>{{ item.value }}</span>
                </div>

                <a-icon
                  v-if="item && item.type === 'file'"
                  type="bell"
                  :style="{ fontSize: '1.6em' }"
                  theme="filled"
                />
                <p v-if="item && item.type === 'file'">{{ item.title }}</p>
                <button v-if="item && item.type === 'file'">
                  <a-icon
                    type="bell"
                    :style="{ fontSize: '1.6em' }"
                    theme="filled"
                  />
                  <p>Descargar Archivo</p>
                </button>

                <Accordion
                  v-if="item && item.type === 'accordion'"
                  :header="item.title"
                >
                  <div :class="'boxValues'">
                    <div
                      v-for="(item2, index3) in item.content"
                      v-bind:key="index3"
                      :class="
                        processStyles(
                          item2 && item2.type === 'text'
                            ? 'boxText section '
                            : 'boxFile section ',
                          item2,
                          'width',
                          'w-100'
                        )
                      "
                    >
                      <p v-if="item2 && item2.type === 'text' && item2.title">
                        {{ item2.title }}
                      </p>
                      <small
                        v-if="item2 && item2.type === 'text' && item2.subtitle"
                        >{{ item2.subtitle }}</small
                      >
                      <div
                        v-if="item2 && item2.type === 'text' && item2.value"
                        class="boxValue"
                      >
                        <span>{{ item2.value }}</span>
                      </div>

                      <div
                        v-if="
                          item2 &&
                          item2.type === 'newTracking' &&
                          showNewTracking
                        "
                        class="w-100"
                      >
                        <BaseInput
                          type="date"
                          label="Fecha de registro"
                          v-model="newTrackingData.date"
                        />
                      </div>
                      <div
                        v-if="
                          item2 &&
                          item2.type === 'newTracking' &&
                          showNewTracking
                        "
                        class="w-100 mb-1"
                      >
                        <BaseTextarea
                          label="Reporte de seguimiento de la DCF al plan de acción seleccionado."
                          v-model="newTrackingData.descriptionReport"
                          type="text"
                          maxlength="1000"
                        />
                      </div>

                      <div
                        v-if="item2 && item2.type === 'newTracking'"
                        class="mb-2 ps-2 w-100"
                      >
                        <a-button
                          class="flex items-start justify-start align-center items-center h-60"
                          shape="round"
                          :block="true"
                          @click="changeShowTracking"
                        >
                          <span
                            class="d-flex items-start justify-start align-center items-center font-bold"
                          >
                            <a-icon
                              type="plus"
                              :style="{
                                fontSize: '20px',
                                background: '#1890ff',
                                borderRadius: '25px',
                                padding: '5px',
                                color: 'white',
                              }"
                              class="font-normal mr-2"
                            />
                            <b>Hacer nuevo seguimiento</b>
                          </span>
                        </a-button>
                      </div>

                      <a-icon
                        v-if="item2 && item2.type === 'file'"
                        type="bell"
                        :style="{ fontSize: '1.6em' }"
                        theme="filled"
                      />
                      <p v-if="item2 && item2.type === 'file'">
                        {{ item2.title }}
                      </p>
                      <button v-if="item2 && item2.type === 'file'">
                        <a-icon
                          type="bell"
                          :style="{ fontSize: '1.6em' }"
                          theme="filled"
                        />
                        <p>Descargar Archivo</p>
                      </button>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
      <div class="flex flex-row gap-3" v-if="showNewTracking">
        <a-button
          type="primary"
          shape="round"
          :block="true"
          ghost
          @click="changeShowTracking"
          >CANCELAR</a-button
        >
        <a-button
          type="primary"
          shape="round"
          :block="true"
          @click="submitNewTracking"
          >AÑADIR SEGUIMIENTO</a-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from "@/components/Dashboard/FCO/Accordion.vue";
export default {
  data() {
    return {
      newTrackingData: {},
      showNewTracking: false,
      mockup: [
        {
          type: "accordion",
          title: "Detalle del plan de accion",
          content: [],
        },
        {
          type: "accordion",
          title: "Reportes de avance",
          content: [],
        },
        {
          type: "accordion",
          title: "Seguimientos",
          content: [
            {
              type: "accordion",
              title: "Seguimiento 22/06/2023",
              content: [
                {
                  type: "text",
                  title: "Fecha de registro",
                  value: "26/12/2023",
                  width: "w-100",
                },
                {
                  type: "text",
                  title:
                    "Reporte de seguimiento de la DCF al plan de accion seleccionado.",
                  value: "lorem ipsum dolor",
                  width: "w-100",
                },
                {
                  type: "newTracking",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  components: { Accordion },
  methods: {
    processStyles(base, item, key = "width", defaultStyle = "") {
      if (item && item[key]) {
        return base + " " + item[key];
      } else {
        return defaultStyle;
      }
    },
    changeShowTracking() {
      this.showNewTracking = !this.showNewTracking;
    },
    submitNewTracking() {
      // aca va el post al back
      this.showNewTracking = !this.showNewTracking;
    },
  },
  name: "viewRecommendation",
};
</script>
  
<style>
.h-60 {
  height: 60px;
}
.simpleCard {
  margin-right: auto;
  max-width: 1020px;
  border: 1px solid #ddd6d6;
  border-radius: 10px;
  padding: 30px;
}

.accordionBox,
.accordionBox > div {
  width: 100% !important;
}

.boxValues {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}

.boxValues > div {
  padding: 20px;
}

.customTitle {
  font-size: 1.5em;
}

.section {
  margin-bottom: 10px;
}

.boxText {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  align-content: start;
}

.boxText > p,
.boxText > small {
  color: #939393;
  margin-right: auto;
  font-weight: bold;
}

.boxValue {
  background: #f9f8f8;
  flex-wrap: wrap;
  padding: 10px;
  margin-top: 5px;
  width: 100%;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-content: center;
}

.boxFile {
  background: #f9f8f8;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  border-radius: 15px;
}

.boxFile > button {
  display: flex;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 15px 50px;
  border-radius: 5px;
  color: white;
  background: #004884;
}

.boxFile > button > i {
  margin-right: 10px;
}

.boxFile > i {
  margin-left: 40px;
}

.boxFile > p {
  margin: 0 auto 0 10px;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}
</style>
  